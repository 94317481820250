import { template as template_149741d8438a4d0ea8b6ff15ecacd4d6 } from "@ember/template-compiler";
const FKLabel = template_149741d8438a4d0ea8b6ff15ecacd4d6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
